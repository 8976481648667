<template>
	<div class="page">
		<div class="w680">
			<topHeader @goBack="goBack()" :isShowBack="isShowWordCard"></topHeader>
			<div class="search-item">
				<el-input class="input" placeholder="查找您想要的单词" v-model="keywords">
					<i slot="prefix" class="el-input__icon el-icon-search icon-search"></i>
				</el-input>
				<el-button class="sub-btn" @click="handleinput">查询</el-button>
			</div>
			<!-- 是否显示的是单词卡 -->
			<wordCard v-if="isShowWordCard" class="word-card" :wordId="wordobj.id"></wordCard>

			<div class="not-show" v-else>
				<div class="col-title" v-if="historyData.length>0">
					<div class="left-line"></div>
					<span>历史记录</span>
				</div>

				<div class="history-list" v-if="historyData.length>0">
					<el-button @click="handlehisword(item)" class="history-item" v-for="(item, index) in historyData"
						:key="index">{{ item }}</el-button>
				</div>

				<div class="col-title">
					<div class="left-line"></div>
					<span>搜索结果</span>
				</div>

				<div class="search-list">
					<div class="search-col" @click="entryWordCard(index)" v-for="(item, index) in wordList" :key="index">
						<img src="@/assets/static/sounds_icon.png" mode="" />
						<div class="right">
							<div class="word">{{item.word}}</div>
							<div class="desc clamp">
								<span class="desc1" v-for="(sitem, sindex) in item.definitionList"
									:key="sindex">{{ sitem.part }}
									<span class="desc2" v-for="(mitem, mindex) in sitem.means"
										:key="mindex">{{ mitem }}<span v-html="'&emsp;'"></span>
									</span>
								</span>
							</div>
						</div>
					</div>
					<div style="display: flex;justify-content: center;margin-top: 20px;" v-if="wordList.length>0&&!complete"> <el-button @click="getworklist">点击加载更多</el-button></div>
				</div>
				<!-- 空 -->
				<div class="data_empty_box" style="padding-top: 68px;" v-if="wordList.length<=0">
					<div class="icon"><img class="img100" :src="require('@/assets/static/kong.png')"></img></div>
					<div class="text">暂无数据</div>
				</div>
				<!-- 空 -->
			</div>
		</div>
	</div>
</template>

<script>
	import topHeader from "@/components/topHeader/topHeader.vue";
	import wordCard from "@/components/wordCard/wordCard.vue";
	import {showLoading,hideLoading} from '@/common/loading.js';
	export default {
		components: {
			topHeader,
			wordCard,
		},
		data() {
			return {
				localKeyword: 'hiskeyword',//历史搜索key
				isShowWordCard: false, // 是否进入词卡页面
				historyData: [],
				keywords:"",
				wordList:[],//单词
				currentPage:1,
				complete:false,
				wordobj:"",//点击的单词
			};
		},
		created() {
			this.historyData = this.$util.getJson(this.localKeyword) || [];
		},
		methods: {
			//点击历史
			handlehisword(value){
				if(value) {
					this.keywords = value
					this.wordList = []
					this.currentPage = 1
					this.complete = false
					this.getworklist()
				}else{
					this.$cache.showfailToast("请输入关键词")
				}
			},
			//搜索
			handleinput(event){
				var value = this.keywords
				if(value) {
					let keywords = this.$util.getJson(this.localKeyword) || [];
					if (keywords.indexOf(this.keywords) === -1) {
						this.historyData.push(this.keywords)
						this.$util.insertkey({
							key: this.localKeyword,
							value: this.keywords,
							capacityNum: 10 //队列容量
						});
						this.wordList = []
						this.currentPage = 1
						this.complete = false
						this.getworklist()
					}else{
						this.getworklist()
					}
				}else{
					this.$message.error("请输入关键词")
				}
			},
			//请求
			getworklist(){
				var _this = this
				this.goBack()
				if(this.complete){
					this.$message.error("已经没有更多了")
					return false;
				}
				var params = {
					word: this.keywords,
					currentPage: this.currentPage,
					pageSize:10
				}
				showLoading()
				this.$http.post('app/word/words/index', params).then(function(res) {
					//数据处理
					hideLoading()
					if (res.code == 200) {
						var records = res.data.records
						if(records.length>0){
							records.forEach((item, index) => {
								item["definitionList"] = []
								try{
									item["definitionList"] = JSON.parse(item.definition)
								}catch(e){
									item["definitionList"] = []
									//TODO handle the exception
								}
							});
							_this.wordList = _this.wordList.concat(records)
							_this.currentPage +=1
						}else{
							_this.complete = true
						}
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//删除历史记录
			deleteKeyword() {
				//清空最近搜索的关键词
				this.historyData = [];
				this.$util.setJson(this.localKeyword,this.historyData)
			},
			goBack() {
				this.isShowWordCard = false;
			},
			entryWordCard(index) {
				this.wordobj = this.wordList[index]
				this.isShowWordCard = true;
			},
		},
	};
</script>

<style lang="scss" scoped>
	/deep/ .el-input__inner {
		border: unset !important;
	}

	.search-item {
		height: 60px;
		display: flex;
		align-items: center;
		padding: 0 20px;
		background: #ffffff;
		border-radius: 12px;
		box-shadow: 0px 3px 15.5px 0px #0000001a;
		width: 680px;
		margin: 40px auto 0;

		.input {
			width: 500px;
			letter-spacing: 1px;
		}

		.icon-search {
			transform: scale(1.5);
		}

		.sub-btn {
			background: #67adff;
			color: #fff;
			font-size: 14px;
			width: 100px;
			height: 32px;
			margin-left: auto;
			display: flex;
			align-items: center;
			justify-content: center;
			box-shadow: 0px -2px 0px 0px #3e97fe inset;
		}
	}

	.word-card {
		margin: 40px auto;
		background: #f7f7f7;
	}

	.not-show {
		margin-bottom: 40px;

		.col-title {
			margin-top: 40px;
			font-size: 20px;
			font-weight: bold;
			display: flex;
			align-items: center;
			color: #5c3704;

			.left-line {
				width: 2px;
				border-radius: 2px;
				height: 14px;
				margin-right: 16px;
				background: #51d9a5;
			}
		}

		.history-list {
			margin-top: 14px;

			.history-item {
				margin-right: 8px;
				background: #e3f0ff;
				color: #4098ff;
				font-size: 16px;
				font-weight: bold;
				border: unset;
				transition: all 0.3s;

				&:hover {
					background: #d6e0ec;
				}
			}
		}

		.search-list {
			.search-col {
				width: 100%;
				box-sizing: border-box;
				margin-top: 12px;
				border-radius: 12px;
				display: flex;
				background: #f7f7f7;
				padding: 12px 12px 12px 24px;
				align-items: center;
				cursor: pointer;
				border: #fff 1px solid;
				transition: all 0.3s;

				&:hover {
					border: #ddd 1px solid;
				}

				img {
					width: 32px;
					height: 32px;
					&:hover {
						transform: scale(1.05);
					}
				}

				.right {
					margin-left: 12px;
					flex: 1;
					.word {
						font-size: 16px;
						font-weight: bold;
					}

					.desc {
						width: 598px;
						margin-top: 8px;
						font-size: 14px;
						color: #999999;
					}
				}
			}
		}
	}
</style>